import { Controller } from "stimulus"
import ApplicationController from './application_controller.js'


export default class extends ApplicationController {

  toggle() {
  	var fullItems = document.getElementsByClassName("full_update")
  	Object.entries(fullItems).forEach(item => {
  		item[1].classList.toggle("hidden")
  	})
  	var incrementalItems = document.getElementsByClassName("incremental_update")
  	Object.entries(incrementalItems).forEach(item => {
  		item[1].classList.toggle("hidden")
  	})
  }

  getChildren(event) {
	this.stimulate('FormularyIdSelectReflex#get_formulary_children', event.target.value);
  }
}